import React from "react"
import { Link } from "gatsby";
import "../styles/post-link.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function PostLink({ post }) {
    const { title, updatedAt, oldItem } = post;
    const description = post.description.description;
    const pageLink = `/post/${post.slug}/`
    const imageUrl = getImage(post.image.gatsbyImageData);

    return (
        <div>
            <div className="post-link post-link-anchor">
                <div>
                    <GatsbyImage 
                        image={imageUrl} alt="" />
                </div>
                <div className="post-link-text">
                    <Link to={pageLink}>
                        <h2>{title}</h2>
                    </Link>
                    <p className="post-link-body">{description}</p>
                    {oldItem !== null?
                        <p className="post-link-date">{oldItem}</p>
                        :
                        <p className="post-link-date">{updatedAt}</p>
                    }
                </div>
            </div>
        </div>
    )
}


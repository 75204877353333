import React from "react"
import "../styles/hero.css"
import { StaticImage } from "gatsby-plugin-image"

export default function Hero() {
    return (
        <div className="hero">
            <h1 className="hero-text">
                技術エラーブログ<br />
            </h1>
            <div className="hero-author">
                <StaticImage src="../images/image.png" className="hero-author-image" alt="合同会社FoodIT" />
                <p className="hero-author-text">
                    Written by Engineer(Server-side / Frontend / infrastructure).<br />
                    Engineer at FoodIT.Inc. javascript in training.
                </p>
            </div>
        </div>
    )
}
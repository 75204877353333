import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import PostLink from "../components/post-link"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import OG_IMAGE from "../../static/logo.png"

export default function Home({ data }) {
  return (
    <Layout>
      <Seo title="Error Blog" description="Error関連及び改善を上げたブログです" image={OG_IMAGE} />
      <Hero />
      {data.allContentfulFoodITblog.edges.map(edge =>
        <PostLink 
        key={edge.node.slug} 
        post={edge.node} />
      )}
    </Layout>
  )
}

export const query = graphql`
query allContentfulFoodITblog {
  allContentfulFoodITblog(sort: {fields: createdAt, order: DESC}) {
    edges {
      node {
        id
        title
        image {
          filename
          url
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 50
          )
          gatsbyImage(
            width: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 50
          )
        }
        description {
          description
        }
        oldItem(formatString: "YYYY年MM月DD日")
        createdAt(formatString: "yyyy/mm/dd")
        updatedAt(formatString: "YYYY年MM月DD日")
        slug
      }
    }
  }
}

`
/*
export const imageQuery = graphql`
  query {
    imagesAll: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
    }
  }
`;
*/